export const initReadMore = function () {
  const linkMore = document.querySelectorAll('.services__link-more');

  if (linkMore) {
    linkMore.forEach((item) => {
      item.addEventListener('click', function (evt) {
        evt.preventDefault();

        let parent = item.closest('.services__item');
        if (parent.closest('.is-show')) {
          parent.classList.remove('is-show');
        } else {
          let services = document.querySelectorAll('.services__item');
          services.forEach((i) => {
            if (i.closest('.is-show')) {
              i.classList.remove('is-show');
            }
          });
          // let positionPage = parent.offsetTop;
          // let positionPage = window.pageXOffset;
          // console.log(positionPage);

          parent.classList.add('is-show');
          const indentPage = -100 + `px`;
          parent.scrollIntoView(indentPage);

        }
      })
    })
  }
};
