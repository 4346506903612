export const initFormSubmit = function () {
  // inputmask
  const form = document.querySelector('.auth__form');
  const auth = document.querySelector('.auth');

  if (form) {

    // eslint-disable-next-line no-undef
    const validation = new JustValidate('#js-auth', {
      errorFieldCssClass: 'is-invalid',
      successFieldCssClass: 'is-valid',
    });
    const messageSuccess = document.querySelector('.auth__message[data-message-success]');
    const messageError = document.querySelector('.auth__message[data-message-error]');

    validation
      .addField('input[data-name]', [{
        rule: 'minLength',
        value: 3,
        errorMessage: 'Must be at least 3 characters'
      },
      {
        rule: 'maxLength',
        value: 50,
        errorMessage: 'Must be no more than 30 characters'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Enter you name!'
      }
      ])

      .addField('input[data-email]', [{
        rule: 'required',
        value: true,
        errorMessage: 'Enter you email!',
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Please enter a valid Email!',
      },
      ])

      .addField('input[data-phone]', [{
        rule: 'required',
        value: true,
        errorMessage: 'Enter you email!',
      }]).onSuccess((event) => {
        // console.log('Validation passes and form submitted', event);

        const formData = new FormData(event.target);
        // console.log(...formData);

        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
          form.classList.add('is-sending');
          if (xhr.readyState === 4) {
            auth.classList.add('is-send');
            if (xhr.status === 200) {
              // console.log('Отправлено');
              form.classList.remove('is-sending');
              messageSuccess.classList.add('is-active');
            } else {
              messageError.classList.add('is-active');
            }
          }
        };

        xhr.open('POST', '../lib/php/sendmail.php', true);
        xhr.send(formData);

        event.target.reset();
      });
  }
};
