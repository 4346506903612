export const initCookies = function () {
  const cookieDate = localStorage.getItem('cookieDate');
  const cookieNotification = document.querySelector('.cookies');
  const cookieBtn = document.querySelector('.cookies__accept');

  if (cookieNotification) {

    // Если записи про кукисы нет или она просрочена на месяц, то показываем информацию про кукисы
    if (!cookieDate || (+cookieDate + 2592000) < Date.now()) {
      cookieNotification.classList.add('is-show');
    }

    // При клике на кнопку, в локальное хранилище записывается текущая дата в системе UNIX
    cookieBtn.addEventListener('click', () => {
      localStorage.setItem('cookieDate', Date.now());
      cookieNotification.classList.remove('is-show');
    });
  }
};
