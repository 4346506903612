export const initDate = () => {
  const today = new Date();
  const dateYear = new Date().getFullYear();
  const dateContainer = document.querySelector('.intro__date');
  const yearContainer = document.querySelector('.footer__copyright span');
  const html = document.querySelector('html');
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  // options.timeZone = 'UTC';
  // options.timeZoneName = 'short';

  const todayDate = today.toLocaleString('en-US', options);
  const todayDateRuYear = today.toLocaleString('ru-RU', options);
  const todayDateRu = todayDateRuYear.replace(/...$/, '');

  if (dateContainer) {
    if (html.getAttribute('lang') === 'ru') {
      dateContainer.textContent = todayDateRu;
    } else {
      dateContainer.textContent = todayDate;
    }
  }

  if (yearContainer) {
    yearContainer.textContent = dateYear;
  }
};
